<template>
  <div>
    <v-container class="page-title page-title--advanced">
      <v-row>
        <v-col class="page-title__title-box">
          <h4 class="page-title__title">{{ $t('heading.instance.advanced.security.title') }}</h4>
        </v-col>
      </v-row>
    </v-container>
    <v-container>
      <v-row>
        <v-col cols="12" class="pb-2">
          <auto-updates-card-skeleton v-if="fetchingSslInfo" />

          <v-card elevation="4" v-else>
            <v-card-text
              class="d-flex justify-space-between text--gray-darken2"
            >
              <div>
                <h6>{{ $t('heading.instance.advanced.security.certificate.title') }}</h6>
                <p class="mb-0 mt-1">
                  {{ $t('heading.instance.advanced.security.certificate.subtitle') }}
                </p>
              </div>
              <div class="d-flex align-center">
                <span
                  v-if="sslInfo.certificate_installed"
                  class="p-3 d-flex align-center"
                  :class="
                    sslInfo.certificate.self_signed ||
                    !sslInfo.certificate.name_match
                      ? 'error--text'
                      : ''
                  "
                >
                  {{ $t('heading.instance.advanced.security.certificate.label') }}
                  <ssl-indicator
                    :fetching="false"
                    :ssl="sslInfo"
                    :isIcon="false"
                    class="mx-4"
                  />
                </span>

                <v-btn
                  elevation="0"
                  class="mt-0 pt-0"
                  outlined
                  color="gray"
                  small
                  :loading="sslButton.loading"
                  @click="loadEditSslModalFromInstance(instance, sslButton)"
                  v-if="instance.getUserPrivileges('hosting.manage_ssl')"
                >
                  <span class="heading--text font-weight-bold">{{ $t('button.manage') }}</span>
                </v-btn>
                <!-- <v-switch
                  class="mt-0"
                  hide-details=""
                  flat
                  @click.prevent="handleSSLChange"
                  v-model="sslInfo.certificate_installed"
                ></v-switch> -->
              </div>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col cols="12" class="pb-2">
          <auto-updates-card-skeleton v-if="fetchingSslInfo" />

          <v-card elevation="4" v-else>
            <v-card-text
              class="d-flex justify-space-between text--gray-darken2"
            >
              <div>
                <h6>{{ $t('heading.instance.advanced.security.forceRedirect.title') }}</h6>
                <p class="mb-0 mt-1">
                  {{ $t('heading.instance.advanced.security.forceRedirect.subtitle') }}
                </p>
              </div>
              <div class="d-flex align-center">
                <ButtonSwitch
                  class="mt-0"
                  hide-details=""
                  :loading="httpsLoading"
                  :disabled="!sslInfo.can_https_redirect"
                  :class="!sslInfo.force_https_redirect ? 'switch-container--disabled' : ''"
                  flat
                  v-model="sslInfo.force_https_redirect"
                  @click.native.stop.prevent="handleSwitchChange"
                />
              </div>
            </v-card-text>
          </v-card>
        </v-col>

        <!-- <v-col cols="12">
          <v-btn @click="saveChanges" elevation="0" large color="primary">
            Save Changes
          </v-btn>
        </v-col> -->
      </v-row>
    </v-container>
    <v-dialog
      :persistent="modalOptions.persistent"
      v-model="modalOptions.open"
      ref="dialog"
      transition="custom-dialog-transition"
    >
      <div
        class="card-overlay"
        v-if="!modalOptions.persistent"
        @click="modalOptions.open = !modalOptions.open"
      />
      <div class="card-overlay" v-else @click="$refs.dialog.animateClick()" />
      <basic-modal
        style="width: 560px"
        :modalOptions="modalOptions"
        @modal-close="modalClose"
        :key="modalRender"
        ref="modal"
      />
    </v-dialog>
  </div>
</template>

<script>
import Api from "@/apis/Api";

import ActionModalMixin from "../../../mixins/ActionModalMixin";
import EditSslMixin from "../../../mixins/EditSslMixin";

import ButtonSwitch from "../../../components/buttons/ButtonSwitch.vue";
import AutoUpdatesCardSkeleton from "../../../components/cards/AutoUpdatesCardSkeleton.vue";
import BasicModal from "../../../components/modal/BasicModal.vue";
import SslIndicator from "../../../components/security/SslIndicator.vue";

export default {
  components: {
    AutoUpdatesCardSkeleton,
    BasicModal,
    SslIndicator,
    ButtonSwitch,
  },
  mounted() {
    this.fetching = false;
    this.fetchSslInfo();
    this.$on("ssl-certificate-installed", () => {
      Api.hosting(this.instance.hostingId).resetCache();
      this.fetchSslInfo();
    });
  },
  mixins: [ActionModalMixin, EditSslMixin],
  data: function () {
    return {
      fetching: true,
      fetchingSslInfo: true,
      sslInfo: {},
      sslInfoOrg: {},
      applicationId: this.$route.params.id,
      sslButton: {
        loading: false,
      },
      httpsLoading: false,
    };
  },
  props: {
    instance: Object,
  },
  methods: {
    fetchSslInfo() {
      this.fetchingSslInfo = true;
      Api.get(
        `/server-accounts/${this.instance.hostingId}/ssl/settings/${this.instance.domain}`
      )
        .then((response) => {
          this.sslInfo = response.data.data;
          this.sslInfoOrg = { ...this.sslInfo };
        })
        .catch((error) => {
          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: Api.getErrorMessage(error),
          });
        })
        .finally(() => {
          this.fetchingSslInfo = false;
        });
    },
    saveChanges(value) {
      this.httpsLoading = true;
      Api.put(
        `/server-accounts/${this.instance.hostingId}/ssl/settings/${this.instance.domain}`,
        {
          force_https_redirect: value,
        }
      )
        .then((response) => {
          this.sslInfo = response.data.data;
          this.$store.dispatch("addAlert", {
            success: true,
            successMessage: this.$t('notification.instance.settings.update')
          });
        })
        .catch((error) => {
          this.sslInfo = { ...this.sslInfoOrg };
          this.$store.dispatch("addAlert", {
            success: false,
            errorMessage: Api.getErrorMessage(error),
          });
        })
        .finally(() => {
          this.httpsLoading = false;
        });
    },
    //
    handleSwitchChange() {
      // this.sslInfo.force_https_redirect = !this.sslInfo.force_https_redirect;
      if(!this.sslInfo.can_https_redirect) {
        return;
      }
      const otherInstances = this.sslInfo.domain_shared_instances
        .filter((i) => {
          return i.id != this.instance.id;
        })
        .map((i) => {
          return `${i.name} (${i.url})`;
        });
      if (otherInstances.length) {
        this.resetModal();
        this.modalOptions.title = this.$t('heading.instance.advanced.security.modal.forceRedirect.title');
        this.modalOptions.icon = "$alertwarning";
        this.modalOptions.color = "warning";
        this.modalOptions.persistent = true;
        this.modalOptions.buttons.unshift({
          label: this.$t('button.confirm'),
          color: "warning",
          onclick: () => {
            this.sslInfo.force_https_redirect =
              !this.sslInfo.force_https_redirect;
            this.modalOptions.open = false;
          },
        });

        let message = this.$t('heading.instance.advanced.security.modal.forceRedirect.info', {domain: this.instance.domain});

        let list = "<ul>";
        otherInstances.map((item) => {
          list = list + `<li>${item}</li>`;
        });

        message = message + list + "</ul>";

        this.modalOptions.message = message;
        this.modalOptions.open = true;
        return;
      }
      // this.sslInfo.force_https_redirect = !this.sslInfo.force_https_redirect;

      this.saveChanges(!this.sslInfo.force_https_redirect);
    },
  },
  watch: {
    "sslInfo.force_https_redirect": function (value, oldValue) {
      if (value !== oldValue) {
        this.$nextTick(() => {});
      }
    },
    "modalOptions.open": function (value) {
      value
        ? this.$store.dispatch("lockBodyScroll")
        : this.$store.dispatch("unlockBodyScroll");
    },
  },
};
</script>

<style lang="scss" scoped>
.basic-modal::v-deep {
  ul {
    margin-top: 16px;
    li {
      font-weight: $font-weight-semibold;
      position: relative;
      padding-left: 16px;
      color: #333342;
      &::before {
        content: "";
        position: absolute;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        background: #ff7a2f;
        top: calc(50% - 3px);
        left: 0px;
      }
      &:not(:last-child) {
        margin-bottom: 6px;
      }
    }
  }
}
</style>
